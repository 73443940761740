<template>
  <div>
    <navbar :title="parent.title" route='/categories'/>
    <v-main class="pt-15 px-2 pb-15">
      <subordinate :slides='categories' class="mt-2"/>
      <v-switch class="mx-1 mb-0" v-model="unavailable" label="کالاهای ناموجود"></v-switch>
      <div class="mt-2">
        <div v-for="result_product in results" :key="result_product.id">
        <result v-if="result_product.onhand != 0 && unavailable == false" :result_product = 'result_product'/>
        <result v-if="unavailable == true" :result_product = 'result_product'/> 
        </div>
      </div>
      <infinite-loading v-if="
          results.length > 1 &&
          count_result > 12 &&
          count_result != results.length
        " spinner="waveDots" @infinite="infiniteHandler"
        >
        <span slot="no-results"></span>
      </infinite-loading>
    </v-main>
    <div>
      <actionBar/>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { categories_list } from './models/categories'
import { search_product } from '../search/models/search'
import { viewbasket_products } from '../Basket/models/Basket'
import InfiniteLoading from 'vue-infinite-loading'
import store from '@/store'
export default {
  data: () => ({
    unavailable : false
  }),
  components:{
    navbar: () => import ('@/components/navbar/appBar'),
    result: () => import ('../search/components/searchResultCard'),
    subordinate: () => import ('../home/components/slider/subordinateSlider'),
    actionBar: () => import ('../home/components/navbar/actionBar'),
    InfiniteLoading
  },
  computed: {
    ...mapState({
      categories: state => state.categories.categories,
      results: state => state.search.result,
      count_result: state => state.search.count_result
    }),
    parent () {
      const parent = JSON.parse(localStorage.getItem('category_id'))
      return parent
    }
  },
  created () {
    const basket = localStorage.getItem('basket')
        if (basket) {
          const time = new Date()
          const now = time.getTime()
          const old_time_basket = parseInt(localStorage.getItem('time_basket'))
          if ((old_time_basket + 86400000) < now ) {
            localStorage.removeItem('basket')
            viewbasket_products()
          } else {
          store.dispatch('basket_local', JSON.parse(basket))
          }
        } else {
          viewbasket_products()
        }
    localStorage.removeItem('filter_search')
    const parent = JSON.parse(localStorage.getItem('category_id'))
    if (this.results.length < 1) {
      categories_list(parent.id)
    }
  },
  mounted () {
    if (this.results.length > 1) {
      return
    }
    let filters = localStorage.getItem('filter_search')
    const category = JSON.parse(localStorage.getItem('category_id'))
        if (filters) {
          filters = JSON.parse(filters)
          filters.page = 1
          let tages = category.tag[0]
          if (category.tag.length > 1) {
            for (const key of category.tag) {
              tages = key + ',' + tages
            }
          }
          filters.tags = tages
          localStorage.setItem('filter_search', JSON.stringify(filters))
          setTimeout(() => {
            search_product(filters)
          }, 500)
        } else {
          filters = {}
          filters.page = 1
          let tages = category.tag[0]
          if (category.tag.length > 1) {
            for (const key of category.tag) {
              tages = tages + ',' + key
            }
          }
          filters.tags = tages
          localStorage.setItem('filter_search', JSON.stringify(filters))
          setTimeout(() => {
          search_product(filters)
          }, 500)
        }
  },
  methods: {
     infiniteHandler($state) {
      let filters = localStorage.getItem("filter_search");
      if (filters) {
        filters = JSON.parse(filters);
        filters.page = parseInt(filters.page) + 1;
        localStorage.setItem("filter_search", JSON.stringify(filters));
      }
      search_product(filters).then(({ data }) => {
        if (data.Result.length) {
          $state.loaded();
        } else {
          $state.complete();
        }
      })
    }
  }
}
</script>